<template>
  <b-modal
    id="surgery-patient-modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @show="getPatient"
    @hidden="clearModal"
  >
    <div class="modal-header">
      <div>
        <p class="modal-title">Identificação do paciente</p>
      </div>
      <div>
        <Close class="icon" @click="clearModal" />
      </div>
    </div>
    <div class="around-content">
      <section>
        <GeneralData :form="patient" type="paciente" />
        <b-container fluid class="mt-2">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label for="observations">Mais informações</label>
                <TextArea
                  id="observations"
                  v-model="patient.moreInformation"
                  placeholder="Descrever"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
        <hr />
      </section>

      <section>
        <PersonalData
          :form="patient"
          :genderOptions="genderOptions"
          type="paciente"
        />
        <hr />
      </section>

      <section>
        <ContactData :form="patient" type="paciente" />
        <hr />
      </section>

      <section>
        <AddressData :form="patient" />
        <hr />
      </section>

      <div class="wrapper-button">
        <!-- A ser implementado
        <b-button variant="outline-primary" class="mr-2">
          Imprimir ficha
        </b-button> -->
        <b-button variant="primary" @click="updatePatient">
          Salvar ficha
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'CompanionModal',
  props: {
    patientId: String,
    surgeryInformationId: String,
    updateSurgeryRecord: Function
  },
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    GeneralData: () => import('./FormData/GeneralData'),
    PersonalData: () => import('./FormData/PersonalData'),
    ContactData: () => import('./FormData/ContactData'),
    AddressData: () => import('./FormData/AddressData'),
    TextArea: () => import('@/components/General/TextArea')
  },
  created() {
    this.patient = this.defaultForm()
  },
  data: () => ({
    patient: {},
    canSave: true,
    genderOptions: [
      { value: 'M', name: 'Masculino' },
      { value: 'F', name: 'Feminino' }
    ]
  }),
  methods: {
    async getPatient() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.getCompletePatient(this.patientId)
        this.patient = {
          ...data,
          address: data.address || this.getDefaultAddress(),
          birthday: data.birthday ? this.moment(data.birthday).toDate() : null,
          gender: this.genderOptions.find(
            gender => gender.value === data.gender
          )
        }
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async updatePatient() {
      if (!this.canSave) return
      this.canSave = false
      if (this.patient.email && !this.validateEmail()) {
        this.$toast.error('Email inválido')
        this.canSave = false
        return
      }
      const isLoading = this.$loading.show()
      try {
        const formData = new FormData()
        this.helpers.buildFormData(formData, {
          ...this.patient,
          gender: this.patient.gender?.value,
          birthday: this.patient.birthday
            ? this.moment(this.patient.birthday, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              )
            : null
        })
        await this.api.updatePerson(this.patientId, formData)
        await this.updateSurgeryRecord('Preenchido')

        this.$bvModal.hide('surgery-patient-modal')
        this.canSave = true
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    defaultForm() {
      return {
        name: '',
        cpf: '',
        rg: '',
        moreInformation: '',
        email: '',
        cellphone: '',
        gender: '',
        phone: '',
        address: {
          zipCode: '',
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: ''
        }
      }
    },
    getDefaultAddress() {
      return {
        zipCode: '',
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: ''
      }
    },
    clearModal() {
      this.patient = this.defaultForm()
      this.$bvModal.hide('surgery-patient-modal')
    },
    validateEmail() {
      return Boolean(
        String(this.patient.email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      )
    }
  }
}
</script>

<style lang="scss">
#surgery-patient-modal {
  h3 {
    color: var(--blue-500);
    font-weight: 700;
    font-size: min(2.6vw, 18px);
  }

  .modal-content {
    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px !important;

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        .icon {
          width: 25px;
          fill: var(--neutral-500);
        }
      }

      .around-content {
        padding: 24px 24px 0 24px;

        .orientation {
          color: var(--type-active);
          font-weight: 600;
          font-size: min(1.2vw, 16px);
        }
      }
      .wrapper-button {
        width: 100%;
        display: inline-flex;
        margin: 20px 0 !important;
        justify-content: flex-end;

        button {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
